<template>
    <div class="answer">
        <el-row style="padding-top: 10px">
            <el-col :span="2">
                <el-button type="success" @click="ShowAnswuer"
                    >添加比赛答案</el-button
                >
            </el-col>
        </el-row>
        <table>
            <thead>
                <tr>
                    <th>比赛名称</th>
                    <th>求值函数类型</th>
                    <th>文件名称</th>
                    <th>文件大小</th>
                    <th style="width: 80px; text-align: center">操作</th>
                    <!-- <th>排位分</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in searchAnswerList" :key="index">
                    <td>{{ item.challenge_title }}</td>
                    <td>{{ item.evaluation_function }}</td>
                    <td>{{ item.filename }}</td>
                    <td>{{ sizeTostr(item.sub_time) }}</td>
                    <td>
                        <i
                            style="
                                cursor: pointer;
                                padding-left: 14px;
                                font-size: 18px;
                                float: left;
                                color: rgb(24, 144, 255);
                            "
                            title="修改"
                            class="el-icon-edit"
                            @click="GetuploadAnswuer(item)"
                        ></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <el-dialog
            :show-close="false"
            title="添加比赛答案"
            :visible.sync="AnswuerState"
            width="40%"
        >
            <el-form
                :model="answuerParam"
                :rules="rules"
                ref="answuerForm"
                label-width="140px"
                class="demo-ruleForm"
            >
                <el-form-item v-if="!IsUpload" label="上传比赛" prop="match_id">
                    <el-select
                        style="width: 100%"
                        v-model="answuerParam.match_id"
                        clearable
                        placeholder="请选择上传比赛对应答案"
                    >
                        <el-option
                            v-for="item in matchList"
                            :key="item.id"
                            :label="item.challenge_title"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="IsUpload" label="上传比赛" prop="match_id">
                    <el-input disabled v-model="matchTitle"></el-input>
                </el-form-item>
                <el-form-item
                    label="上传比赛答案类型"
                    prop="evaluation_function"
                >
                    <el-select
                        style="width: 100%"
                        v-model="answuerParam.evaluation_function"
                        clearable
                        placeholder="请选择上传比赛答案类型"
                    >
                        <el-option
                            v-for="item in funcList"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="答案文件">
                    <el-upload
                        class="upload-demo"
                        ref="uploads"
                        action=""
                        :on-remove="handleRemove"
                        :file-list="answuerParam.file"
                        :on-change="FileAnswuerChange"
                        accept=".xlsx,.xls,.csv"
                        :auto-upload="false"
                    >
                        <el-button slot="trigger" type="primary"
                            >选取文件</el-button
                        >
                        <div
                            slot="tip"
                            class="el-upload__tip"
                            style="color: red"
                        >
                            只能上传xlsx、xls、csv文件，且不超过10MB
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="Cancelanswuer">取 消</el-button>
                <el-button type="primary" @click="AddAnswuer">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { function_list } from "../../api/ManagementCenter.js";
import { publicbaseURLs } from "@/utils/request";
import { getToken } from "@/utils/auth";
import axios from "axios";
export default {
    data() {
        return {
            AnswuerState: false,
            IsUpload: false,
            funcList: [],
            matchList: [],
            UrlApi: publicbaseURLs() + "/upload_answuer",
            answuerParam: {
                file: [],
                match_id: "",
                evaluation_function: "",
            },
            matchTitle: "",
            rules: {
                match_id: [
                    {
                        required: true,
                        message: "请选择需要上传的比赛",
                        trigger: "blur",
                    },
                ],
                evaluation_function: [
                    {
                        required: true,
                        message: "请选择需要上传答案函数类型",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: {
        searchAnswerList: {
            type: Array,
            default: null,
        },
    },
    methods: {
        AddAnswuer() {
            this.$refs["answuerForm"].validate((valid) => {
                if (valid) {
                    if (this.answuerParam.file.length == 0) {
                        this.$message({
                            type: "error",
                            message: "请选择答案文件",
                        });
                        return;
                    }
                    this.$store.commit("app/CHANGE_LOADING", true);
                    var data = new FormData();
                    data.append("file", this.answuerParam.file[0].raw);
                    data.append("match_id", this.answuerParam.match_id);
                    data.append(
                        "evaluation_function",
                        this.answuerParam.evaluation_function
                    );
                    axios({
                        url: this.UrlApi,
                        method: "post",
                        data: data,
                        headers: {
                            Authorization: getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: "上传成功",
                                });
                                this.$store.commit("app/CHANGE_LOADING", false);
                                this.answuerParam.file = [];
                                this.$emit("GetsearchAnswer");
                                this.Cancelanswuer();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.msg,
                                });
                                this.$store.commit("app/CHANGE_LOADING", false);
                                this.answuerParam.file = [];
                            }
                        })
                        .catch(() => {
                            this.$message({
                                type: "error",
                                message: "上传失败",
                            });
                            this.$store.commit("app/CHANGE_LOADING", false);
                            this.answuerParam.file = [];
                        });
                } else {
                    return false;
                }
            });
        },
        GetuploadAnswuer(item) {
            this.GetfunctionList();
            this.AnswuerState = true;
            this.IsUpload = true;
            this.answuerParam.match_id = item.match_id;
            this.answuerParam.evaluation_function = item.evaluation_function;
            this.matchTitle = item.challenge_title;
        },
        Cancelanswuer() {
            this.AnswuerState = false;
            this.IsUpload = false;
            this.answuerParam = {
                file: [],
                match_id: "",
                evaluation_function: "",
            };
        },
        ShowAnswuer() {
            this.GetfunctionList();
            this.IsUpload = false;
            this.AnswuerState = true;
        },
        GetfunctionList() {
            function_list().then((res) => {
                if (res.code == 200) {
                    this.funcList = res.func_list;
                    this.matchList = res.match_list;
                }
            });
        },
        handleRemove() {
            this.answuerParam.file = [];
        },
        FileAnswuerChange(res) {
            this.answuerParam.file = [res];
        },
        sizeTostr(size) {
            var data = "";
            if (size < 0.1 * 1024) {
                //如果小于0.1KB转化成B
                data = size.toFixed(2) + "B";
            } else if (size < 0.1 * 1024 * 1024) {
                //如果小于0.1MB转化成KB
                data = (size / 1024).toFixed(2) + "KB";
            } else if (size < 0.1 * 1024 * 1024 * 1024) {
                //如果小于0.1GB转化成MB
                data = (size / (1024 * 1024)).toFixed(2) + "MB";
            } else {
                //其他转化成GB
                data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
            }
            var sizestr = data + "";
            var len = sizestr.indexOf(".");
            var dec = sizestr.substr(len + 1, 2);
            if (dec == "00") {
                //当小数点后为00时 去掉小数部分
                return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
            }
            return sizestr;
        },
    },
};
</script>
<style lang="scss" scoped>
.answer {
    table {
        font-size: 14px;
        width: 100%;
        background: #fff;
        margin: 1em 0;
        border: 1px solid rgba(34, 36, 38, 0.15);
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0.28571429rem;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
        border-collapse: separate;
        border-spacing: 0;

        thead {
            box-shadow: none;

            tr {
                th {
                    cursor: auto;
                    background: #f9fafb;
                    text-align: inherit;
                    color: rgba(0, 0, 0, 0.87);
                    padding: 0.92857143em 0.78571429em;
                    vertical-align: inherit;
                    font-style: none;
                    font-weight: 700;
                    text-transform: none;
                    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
                    border-left: 1px solid rgba(34, 36, 38, 0.1);
                }

                th:last-child {
                    width: 50px;
                }
            }
        }

        tbody {
            tr {
                font-size: 13px;

                td {
                    padding: 0.78571429em 0.78571429em;
                    text-align: inherit;
                    border-left: 1px solid rgba(34, 36, 38, 0.1);
                    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
                }

                td:last-child {
                    width: 50px;
                }
            }
        }
    }
}
</style>