import request from "@/utils/request";

// 搜索比赛
export function searchMatchList(data) {
  return request({
    url: "/search/MatchList",
    method: "post",
    data: data,
  });
}
// 新增用户
export function admin_register(data) {
	return request({
    url: "/admin_register",
    method: "post",
    data: data,
  });
}
// 修改用户
export function admin_update_user(data) {
	return request({
    url: "/admin_update_user",
    method: "post",
    data: data,
  });
}
// 批量新增用户
export function bulk_registration(data) {
	return request({
    url: "/bulk_registration",
    method: "post",
    data: data,
  });
}
// 用户搜索
export function searchStudent(data) {
	return request({
	url: "/search/Student",
	method: "post",
	data: data,
	});
}
// 删除用户
export function delete_user(data) {
  return request({
    url: "/delete_user",
    method: "post",
    data: data,
  });
}
// 添加比赛
export function addMatchList(data) {
  return request({
    url: "/MatchList",
    method: "post",
    data: data,
  });
}
// 修改比赛
export function MatchList_update(data) {
  return request({
    url: "/MatchList_update",
    method: "post",
    data: data,
  });
}
// 删除比赛
export function MatchList_delete(data) {
  return request({
    url: "/MatchList_delete",
    method: "post",
    data: data,
  });
}
// 上传图片
export function image_upload(data) {
  return request({
    url: "/image_upload",
    method: "post",
    data: data,
  });
}
// 上传比赛文件
export function file_upload(id,data) {
  return request({
    url: "/file_upload/"+id,
    method: "post",
    data: data,
  });
}
// 删除比赛数据文件
export function match_delete_file(data) {
	return request({
	url: "/match_delete_file",
	method: "post",
	data: data,
	});
}
// 管理员重置用户密码
export function reset_passwords(data) {
	return request({
	url: "/reset_passwords",
	method: "post",
	data: data,
	});
}
// 分数重置（超级管理员）
export function reset_score(data) {
	return request({
	url: "/reset_score",
	method: "post",
	data: data,
	});
}
// 查询权限
export function search_all_permission(data) {
	return request({
	url: "/search_all_permission",
	method: "post",
	data: data,
	});
}
// 增加角色
export function add_role(data) {
	return request({
	url: "/add_role",
	method: "post",
	data: data,
	});
}
// 查询角色
export function search_role(data) {
	return request({
	url: "/search_role",
	method: "post",
	data: data,
	});
}
// 删除角色
export function delete_role(data) {
	return request({
	url: "/delete_role",
	method: "post",
	data: data,
	});
}
// 修改角色
export function update_role(data) {
	return request({
	url: "/update_role",
	method: "post",
	data: data,
	});
}
// 查询审核（管理员）
export function search_apply_list(data) {
	return request({
	url: "/search_apply_list",
	method: "post",
	data: data,
	});
}
// 报名审核（管理员）
export function check_apply(data) {
	return request({
	url: "/check_apply",
	method: "post",
	data: data,
	});
}
// 查看代码（管理员）
export function user_code(data) {
	return request({
	url: "/search/user_code",
	method: "post",
	data: data,
	});
}
// 删除代码（管理员）
export function delete_code(id) {
	return request({
		url: "/delete_code/"+ id,
		method: "post",
	});
}
// 上传比赛答案功能（管理员）
export function upload_answuer(data) {
	return request({
	url: "/upload_answuer",
	method: "post",
	data: data,
	});
}
// 查看函数/比赛列表（管理员）
export function function_list(data) {
	return request({
	url: "/function_list",
	method: "post",
	data: data,
	});
}
// 查看比赛答案列表（管理员）
export function search_answer(data) {
	return request({
	url: "/search_answer",
	method: "post",
	data: data,
	});
}
// 查看管理员列表（管理员）
export function search_admin(data) {
	return request({
	url: "/search_admin",
	method: "post",
	data: data,
	});
}
