import request from "@/utils/request";

// 搜索比赛
export function match_search(data) {
	return request({
	url: "/match_search",
	method: "post",
	data: data,
	});
}
// 搜索比赛数据
export function searchCompetitionDocuments(data) {
	return request({
	url: "/search/CompetitionDocuments",
	method: "post",
	data: data,
	});
}
// 个人历史分数查询
export function search_score(data) {
	return request({
	url: "/search_score",
	method: "post",
	data: data,
	});
}
// 查看分数（每日）
export function search_score_everyday(data) {
	return request({
	url: "/search_score_everyday",
	method: "post",
	data: data,
	});
}
// 分数排名查询\\\
export function user_score_rank(data) {
	return request({
	url: "/user_score_rank",
	method: "post",
	data: data,
	});
}
// 报名参赛
export function competition_entry(data) {
	return request({
	url: "/competition_entry",
	method: "post",
	data: data,
	});
}
// 参赛次数查询
export function upload_num(data) {
	return request({
    url: "/search/upload_num",
    method: "post",
    data: data,
  });
}
// 积分排名（比赛）
export function integral_rank(data) {
    return request({
        url: "/integral_rank",
        method: "post",
        data: data,
    });
}
// 是否报名
export function is_enter_match(data) {
	return request({
	url: "/is_enter_match",
	method: "post",
	data: data,
	});
}
